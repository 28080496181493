.Logo {
  padding: 8px;
  height: 400px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 auto;
}

.LogoImg {
  height: 100%;
  margin: 0 auto;
}

.LogoNav {
  padding: 0px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 5px;
}

.LogoNav img {
  height: 100%;
}
